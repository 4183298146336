.react-daterange-picker__wrapper {
  display: none !important;
}

.react-daterange-picker {
  display: flex !important;
}
.react-daterange-picker abbr {
  text-decoration: none !important;
}

.react-calendar {
  border: 1px solid #a0a09638 !important;
  border-radius: 4px !important;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}